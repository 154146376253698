require('./bootstrap');
// let bootstrap = require('bootstrap');
require('leaflet/dist/leaflet.js');
import Splide from '@splidejs/splide';


document.addEventListener('DOMContentLoaded', function () {
    var direction_page=document.dir;
    // Navbar fixed on scroll (sticky top)
    window.addEventListener('scroll', function() {
        if (window.scrollY > 200) {
          document.getElementById('main-navbar').classList.add('fixed-top');
          // add padding top to show content behind navbar
          let navbar_height = document.querySelector('.navbar').offsetHeight;
          document.body.style.paddingTop = navbar_height + 'px';
        } else {
          document.getElementById('main-navbar').classList.remove('fixed-top');
           // remove padding top from body
          document.body.style.paddingTop = '0';
        }
    });


    // Home page product slider
    const homeProductsSlider =  document.getElementById('home-products-slider');
    if (typeof(homeProductsSlider) != 'undefined' && homeProductsSlider != null)
    {
        const homeSplide = new Splide('#home-products-slider', {
            type: 'loop',
            perPage: 4,
            perMove: 1,
            gap: 0,
            focus  : '-1',
            trimSpace: false,
            pagination: true,
            breakpoints: {
                900: {
                    perPage: 1,
                },
                1200: {
                    perPage: 3,
                },
            }
        });
        homeSplide.mount();
    }

    // Home page testimonials slider
    const homeTestimonialsSlider =  document.getElementById('home-testimonials-slider');
    if (typeof(homeTestimonialsSlider) != 'undefined' && homeTestimonialsSlider != null)
    {
        let TestimonialsSplide = new Splide('#home-testimonials-slider', {
            type: 'loop',
            perPage: 1,
            perMove: 1,
            gap: 0,
            trimSpace: false,
        });
        TestimonialsSplide.mount();
    }
    const homeAnnoucementSlider =  document.getElementById('home-annoucement-slider');
    if (typeof(homeAnnoucementSlider) != 'undefined' && homeAnnoucementSlider != null)
    {

        let morethanoneframe = document.getElementById('morethanoneframe');
        if(morethanoneframe){
            morethanoneframe=morethanoneframe.value;
            if(morethanoneframe>1)
            morethanoneframe=true;
            else
            morethanoneframe=false;
        }


        let annoucement = new Splide('#home-annoucement-slider', {
            type: 'loop',
            arrows             :morethanoneframe ,
            perPage: 1,
            perMove: 1,
            gap: 0,
            trimSpace: false,
        });
        annoucement.mount();
    }


    // product packs slider
    const packsSlider =  document.getElementById('product-packs-slider');
    if (typeof(packsSlider) != 'undefined' && packsSlider != null)
    {
        const PacksSplide = new Splide('#product-packs-slider', {
            type: 'loop',
            perPage: 3,
            perMove: 1,
            gap: 0,
            focus  : 'center',
            trimSpace: false,
            pagination: true,
            direction: direction_page,
            breakpoints: {
                700: {
                    perPage: 1,
                },
                900: {
                    perPage: 2,
                    focus  : 'left',
                    pagination: false,
                },
                1200: {
                    perPage: 3,
                },
            }
        });
        PacksSplide.mount();
    }

});



// Mini Search
const queryInput = document.getElementById("search-input");
const spinner = document.getElementById("spinner");
const searchResults = document.getElementById("search-results");

const fetchQueryResults = (query = queryInput.value, ) => {
    console.log(currentLang);
    let searchBaseUrl = '/';
    if (currentLang == 'ar') {
        searchBaseUrl = '/ar/';
    }
    const url = searchBaseUrl + 'recherche?search=' + queryInput.value;

    // Clear our current results.
    searchResults.innerHTML = "";

    // Stop here if the query is empty.
    if (!query.length) {
        return false;
    }

    // Add spinner.
    spinner.classList.replace("invisible", "visible");

    // Fetch new results.
    axios
        .get(url, { timeout: 6000 })
        .then(response => {
            console.log(response.data);
            searchResults.innerHTML = response.data
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            spinner.classList.replace("visible", "invisible");
        });
  };

const debounce = _.debounce(fetchQueryResults, 300);

queryInput.addEventListener("input", e => {
    debounce();
});


let clickableimages = document.querySelectorAll('img[tt-link-target]')
if(clickableimages){
    clickableimages.forEach(imageElement=>{
        let linkto = imageElement.getAttribute('tt-link-target');
        if(linkto!=''){
            imageElement.style.setProperty('cursor', 'pointer');
            imageElement.addEventListener('click',function(){
                window.open(linkto, '_blank');
            })
        }
    })
}







